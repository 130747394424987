import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    createWorkoutSession,
    featuredWorkouts,
    getAllWorkouts,
    getAllWorkoutVideos,
    getWorkoutById,
    getWorkoutVideos,
    setFavoriteWorkout,
} from './actions/workoutActions';

export interface Workout {
    id: string;
    title: string;
    workout_duration: number;
    description: string;
    created_at: string;
    updated_at: string;
    order?: number;
    featured: boolean;
    published: boolean;
    published_at?: string;
    workout_rest_video_id?: string;
    workout_playlist_item_id?: string;
    trainer_id: number;
    difficulty: number;
    user_id: string;
    rounds: number;
    category_id: string;
    is_daily: boolean;
    trainer_title: string;
    trainer_username: string;
    is_favorite: number;
    workout_cover_image: string;
    workout_cover_image_mobile: string;
    cover_image: string;
    cover_image_mobile: string;
    videos?: WorkoutVideo[];
}

export interface WorkoutVideo {
    id: string;
    title: string;
    thumbnail_image: string;
    trainer_name: string;
    points: number;
    video_duration: number;
    brief: string;
    published: boolean;
    published_at: string;
    created_at: string;
    updated_at: string;
    external_link: string;
    type_of_video: string;
    exercise_id: string;
    equipment_id: string;
    music_genre_id: string;
    musicArtist: string;
    setCount: string;
    repCount: string;
    trainer_id: number;
    featured: boolean;
    order: number;
    exercise_type: string;
    is_favorite: boolean;
    video_url: string;
    exercise_explained_video_url: string;
    media_convert_job_status: string;
    workout_stream_url: string;
    exercise_explained_stream_url: string;
}

export interface WatchedVideo {
    video: WorkoutVideo;
    watchedTime: number;
}

export interface WorkoutState {
    allWorkouts: Workout[];
    allWorkoutVideos: WorkoutVideo[];
    featuredWorkouts: Workout[];
    workout?: Workout;
    currentVideoIndex?: number;
    watchedVideos: WatchedVideo[];
    loading: boolean;
    loadingVideos: boolean;
    error: string;
}

const initialState: WorkoutState = {
    allWorkouts: [],
    allWorkoutVideos: [],
    featuredWorkouts: [],
    watchedVideos: [],
    loading: false,
    loadingVideos: false,
    error: null,
};

const workoutsAdapter = createEntityAdapter({
    selectId: (workout: Workout) => workout.id,
    sortComparer: (a, b) => a.title.localeCompare(b.title),
});

const workoutSlice = createSlice({
    name: 'workouts',
    initialState: workoutsAdapter.getInitialState(initialState),
    reducers: {
        selectWorkout(state, action: PayloadAction<any>) {
            state.workout = action.payload;
        },
        selectVideo(state, action: PayloadAction<any>) {
            state.currentVideoIndex = action.payload;
        },
        addWatchedVideo(state, action: PayloadAction<any>) {
            state.watchedVideos.push(action.payload);
        },
        clearWatchedVideos(state) {
            state.watchedVideos = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(featuredWorkouts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(featuredWorkouts.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.featuredWorkouts = action.payload;
                state.error = null;
            })
            .addCase(featuredWorkouts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getWorkoutById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getWorkoutById.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.workout = action.payload[0];
                state.error = null;
            })
            .addCase(getWorkoutById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getWorkoutVideos.pending, (state) => {
                state.loadingVideos = true;
                state.error = null;
            })
            .addCase(getWorkoutVideos.fulfilled, (state, action: PayloadAction<any>) => {
                state.loadingVideos = false;
                state.workout.videos = action.payload;
                state.error = null;
            })
            .addCase(getWorkoutVideos.rejected, (state, action) => {
                state.loadingVideos = false;
                state.error = action.payload as string;
            })
            .addCase(createWorkoutSession.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createWorkoutSession.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(createWorkoutSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getAllWorkouts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllWorkouts.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.allWorkouts = action.payload.results.data;
                state.error = null;
            })
            .addCase(getAllWorkouts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getAllWorkoutVideos.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllWorkoutVideos.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.allWorkoutVideos = action.payload.results;
                state.error = null;
            })
            .addCase(getAllWorkoutVideos.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(setFavoriteWorkout.fulfilled, (state, action: PayloadAction<any>) => {
                state.workout.is_favorite = action.payload ? 1 : 0;
                state.error = null;
            })
            .addCase(setFavoriteWorkout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { selectVideo, selectWorkout, addWatchedVideo, clearWatchedVideos } = workoutSlice.actions;
export default workoutSlice.reducer;
