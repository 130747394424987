import LogWeightCard from 'components/LogWeightCard';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import BackIcon from '../../assets/img/icons/back-icon.png';
import { logWeight } from '../../redux/actions/videoActions';

const LogWeightPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const { watchedVideos } = useSelector((state: RootState) => state.workouts);
    const [data, setData] = useState<{ duration_time: number; video_id: number; amount_weight_loss: number }[]>([]);

    const handleSetData = useCallback((duration_time, video_id, amount_weight_loss) => {
        setData((prevData) => {
            const existingIndex = prevData.findIndex((item) => item.video_id === video_id);
            if (existingIndex !== -1) {
                const updatedData = [...prevData];
                updatedData[existingIndex] = { duration_time, video_id, amount_weight_loss };
                return updatedData;
            } else {
                return [...prevData, { duration_time, video_id, amount_weight_loss }];
            }
        });
    }, []);

    const handleSubmit = useCallback(() => {
        dispatch(logWeight(data));
    }, [data, dispatch]);

    return (
        <div className="h-dvh font-['SF-Pro-Display']">
            <div className="flex justify-center p-8">
                <Link to={`/workout/${id}`} className="absolute left-10 top-10">
                    <div className="rounded-full bg-black">
                        <img alt="back" src={BackIcon} className="w-18" />
                    </div>
                </Link>
                <h1 className="text-2xl text-white">Log Weight</h1>
            </div>
            <div className="flex flex-col items-center justify-center gap-6 px-6 pb-16">
                {watchedVideos.map(({ video, watchedTime }) => {
                    return <LogWeightCard watchedTime={watchedTime} setData={handleSetData} video={video} />;
                })}
            </div>
            <div className="fixed bottom-0 z-10 flex h-16 w-full justify-center bg-black p-4">
                <button
                    onClick={handleSubmit}
                    className={`flex h-[40px] w-full items-center justify-center rounded-full border border-secondary bg-black text-center text-secondary shadow-glow`}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default LogWeightPage;
