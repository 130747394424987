import Video from 'components/Video';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { WorkoutVideo } from 'redux/workoutSlice';

const VideoPage = () => {
    const { id } = useParams();
    const { allWorkoutVideos } = useSelector((state: RootState) => state.workouts);
    const [video, setVideo] = useState<WorkoutVideo>();

    useEffect(() => {
        console.log('allWorkoutVideos');
        setVideo(allWorkoutVideos.find((video) => video.id === parseInt(id)));
        console.log(video);
    }, []);

    const handleFinishVideo = useCallback((video, watchedTime) => {}, []);

    const handleVideoTick = useCallback(() => {}, []);

    const handleFinishSession = useCallback(() => {}, []);

    return video ? (
        <Video
            videos={[
                {
                    stream_url: null,
                    video_url: video.video_url,
                    trainer_name: video.trainer_name,
                    title: video.title,
                    thumbnail_image: video.thumbnail_image,
                },
            ]}
            videoIndex={0}
            backPath={`/library/exercises`}
            autoPlay={true}
            handleFinishSession={handleFinishSession}
            handleFinishVideo={handleFinishVideo}
            handleVideoTick={handleVideoTick}
        />
    ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div className="loader"></div>
        </div>
    );
};

export default VideoPage;
