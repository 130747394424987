import { useEffect, useState } from 'react';
import { WorkoutVideo } from '../redux/workoutSlice';

const LogWeightCard = (props: {
    watchedTime: number;
    video: WorkoutVideo;
    setData: (videoId, weight, totalWeight) => void;
}) => {
    const { watchedTime, video, setData } = props;
    const [weight, setWeight] = useState<boolean>(false);
    const [totalWeight, setTotalWeight] = useState<number>(0);

    useEffect(() => {
        setData(watchedTime, video.id, totalWeight);
    }, [weight, totalWeight, setData, video.id, watchedTime]);

    return (
        <div className="card relative h-full flex-row border-2 border-secondary p-4 shadow-glow">
            <div className="flex gap-2">
                <figure className="relative w-1/3">
                    <img
                        className="z-0 h-[150px] w-[94px] rounded-lg object-cover"
                        src={video.thumbnail_image}
                        alt={video.title}
                    />
                    <div className="left-0 top-0 z-10 h-full w-full bg-gradient-to-t from-black to-transparent"></div>
                </figure>
                <div className="card-body z-10 gap-2 p-0">
                    <h2 className="card-title uppercase text-white">{video.title}</h2>
                    <div className="flex gap-1">
                        <span className="text-sm font-light">Select: </span>
                        <div className="flex items-center gap-2">
                            <button
                                className={`h-[18px] w-[61px] rounded-full border bg-black text-[9px] ${weight ? 'border-secondary' : 'border-white'}`}
                                onClick={() => setWeight(true)}
                            >
                                Weight
                            </button>
                            <button
                                className={`h-[18px] w-[61px] rounded-full border bg-black text-[9px] ${!weight ? 'border-secondary' : 'border-white'}`}
                                onClick={() => setWeight(false)}
                            >
                                No Weight
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-1">
                        <label className="text-sm font-light">Total: </label>
                        <input
                            type={'number'}
                            className="h-[24px] w-[52px] rounded-full border-[0.5px] bg-[#131313] text-center text-[10px]"
                            onChange={(e) => setTotalWeight(e.target.valueAsNumber)}
                        />
                    </div>
                </div>
            </div>
            <span className="">
                {Math.floor(watchedTime / 60)}:{watchedTime % 60 < 10 ? `0${watchedTime % 60}` : watchedTime % 60} min
            </span>
        </div>
    );
};

export default LogWeightCard;
