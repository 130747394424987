import { Fire } from 'icons';
import Calendar from 'icons/calendar';
import Close from 'icons/close';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { getAllPrograms } from '../../redux/actions/programActions';

const ProgramsLibrary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { allPrograms, loading } = useSelector((state: RootState) => state.programs);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);

    const handleSelectElement = useCallback(
        (element: any) => {
            navigate('/program/' + element.id);
        },
        [navigate]
    );

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        dispatch(getAllPrograms({ page, take, search: debouncedSearch }));
    }, [allPrograms.length, debouncedSearch, dispatch, page, search, take]);

    return (
        <div className="mb-16 flex h-full w-full flex-col gap-6 overflow-hidden pt-4 md:h-5/6 lg:mb-0">
            <div className="z-20 flex items-center justify-center bg-black px-6">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search Programs"
                        className="w-full rounded-full border-2 bg-black py-2 pl-6 pr-4 focus:border-white focus:outline-none"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-3 top-2 text-white">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
            </div>
            <div className="flex w-full flex-grow flex-col items-center gap-3 overflow-y-scroll px-6">
                {loading
                    ? [0, 1, 2, 3, 4, 5, 6].map((val) => (
                          <div key={val} className="skeleton h-[180px] w-[382px] bg-secondary/20"></div>
                      ))
                    : allPrograms.map((program, index) => (
                          <div
                              onClick={() => handleSelectElement(program)}
                              id={index.toString()}
                              key={index}
                              className={`carousel-item flex cursor-pointer items-center justify-center gap-4 rounded-lg`}
                          >
                              <div
                                  className="card relative h-[180px] w-[382px] border-[5px] border-[#131313] shadow-xl hover:shadow-glow"
                                  style={{
                                      backgroundImage: `url(${program.cover_image})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center 20%',
                                  }}
                              >
                                  <div className="card-body z-10 items-start justify-end p-4">
                                      <div className="card-actions flex-col items-start">
                                          <div className="flex items-center space-x-2 rounded-full border border-white bg-black/50 px-4 py-1 text-white">
                                              <div className="flex items-center gap-[2px]">
                                                  <Fire />
                                                  <p className="text-[10px]">{program.program_difficulty}</p>
                                              </div>
                                              <div className="flex items-center gap-[2px]">
                                                  <Calendar />
                                                  <p className="text-[10px]">Days {program.program_duration}</p>
                                              </div>
                                          </div>
                                      </div>
                                      <h2 className="text-2xl text-white">{program.title}</h2>
                                  </div>
                              </div>
                          </div>
                      ))}
            </div>
        </div>
    );
};

export default ProgramsLibrary;
