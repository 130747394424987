import Close from 'icons/close';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import AppLogo from '../assets/img/logotype2.png';
import { getUserScores } from '../redux/actions/leaderboardActions';
import { createWorkoutSession } from '../redux/actions/workoutActions';

const FinishedWorkout = (props: {}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { workout } = useSelector((state: RootState) => state.workouts);
    const { watchedVideos } = useSelector((state: RootState) => state.workouts);
    const { userScore } = useSelector((state: RootState) => state.leaderboard);
    const [duration, setDuration] = useState<number>(0);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>('');

    useEffect(() => {
        setDuration(watchedVideos.reduce((acc, curr) => acc + curr.watchedTime, 0));
    }, [watchedVideos]);

    useEffect(() => {
        dispatch(getUserScores());
    }, [dispatch]);

    const handleSelect = useCallback(
        (e) => {
            setIsSubmitted(true);
            setFeedback(e.target.value);
            dispatch(
                createWorkoutSession({
                    workout_id: parseInt(workout.id),
                    video_id: 418,
                    total_workouts: watchedVideos.length,
                    total_points: userScore.points,
                    strake_days: userScore.best_streak,
                    feedback: e.target.value,
                    workout_duration: Math.floor(duration),
                })
            );
        },
        [dispatch, duration, userScore.best_streak, userScore.points, watchedVideos.length, workout.id]
    );

    const handleLogWeight = useCallback(() => {
        navigate(`/workout/${workout.id}/video/log-weight`);
    }, [navigate, workout.id]);

    return (
        <div className="flex h-dvh w-screen flex-col items-center justify-evenly bg-smoke-blue bg-cover bg-bottom px-6 pt-4 font-['SF-Pro-Display'] md:w-10/12 lg:w-7/12 xl:w-5/12">
            <Link to={`/workout/${workout.id}`} className="h-8 w-8 self-start">
                <div className="h-8 w-8 bg-black">
                    <Close className="h-8 w-8 rounded-full p-1 shadow-glow" />
                </div>
            </Link>
            <div className="flex w-[321px] flex-col items-center">
                <img alt="sweatsonic" src={AppLogo} className="w-[267px]" />
                <span className="text-white">Let's Go!</span>
                <div className="flex w-full justify-center">
                    <p className="text-lg text-white">That Was Your 100th Workout!</p>
                </div>
            </div>
            <div className="flex h-4/5 w-full flex-col gap-8">
                <ul className="flex w-full flex-col space-y-2 rounded-lg border-2 border-secondary bg-black p-4 shadow-glow">
                    <li className="flex w-full justify-between">
                        <p className="text-white">Duration</p>
                        <span className="text-sm text-[#979797]">
                            {Math.floor(duration / 60)}:
                            {Math.floor(duration % 60)
                                .toString()
                                .padStart(2, '0')}{' '}
                            min
                        </span>
                    </li>
                    <li className="flex w-full justify-between">
                        <p className="text-white">Current Streak</p>
                        <span>{userScore.best_streak}</span>
                    </li>
                    <li className="flex w-full justify-between">
                        <p className="text-white">Total Workouts</p>
                        <span>{userScore.workout_session_count}</span>
                    </li>
                </ul>
                <div className="w-full">
                    <button
                        onClick={handleLogWeight}
                        className={`flex h-[40px] w-full items-center justify-center rounded-full border border-secondary bg-black text-center text-secondary shadow-glow`}
                    >
                        Log Weight?
                    </button>
                </div>
                <div className="flex w-full flex-col items-center gap-6">
                    <p className="p-2 text-xl text-white">How was that for you?</p>
                    <form className="flex w-full flex-col justify-around gap-4">
                        <label
                            className={`h-[40px] rounded-full border bg-black text-center ${isSubmitted && feedback !== 'EASY' ? 'btn-disabled' : ''} ${feedback === 'EASY' ? 'pointer-events-none cursor-default border-secondary text-secondary shadow-glow' : 'border-white'} flex items-center justify-center`}
                        >
                            <span className="tracking-wider">Too Easy</span>
                            <input
                                type="radio"
                                checked={feedback === 'Easy'}
                                className="hidden"
                                value={'EASY'}
                                onChange={(e) => handleSelect(e)}
                            />
                        </label>
                        <label
                            className={`h-[40px] rounded-full border bg-black text-center ${isSubmitted && feedback !== 'MEDIUM' ? 'btn-disabled' : ''} ${feedback === 'MEDIUM' ? 'pointer-events-none cursor-default border-secondary text-secondary shadow-glow' : 'border-white'} flex items-center justify-center`}
                        >
                            <span className="tracking-wider">Medium</span>
                            <input
                                type="radio"
                                checked={feedback === 'Medium'}
                                className="hidden"
                                value={'MEDIUM'}
                                onChange={(e) => handleSelect(e)}
                            />
                        </label>
                        <label
                            className={`h-[40px] rounded-full border bg-black text-center ${isSubmitted && feedback !== 'HARD' ? 'btn-disabled' : ''} ${feedback === 'HARD' ? 'pointer-events-none cursor-default border-secondary text-secondary shadow-glow' : 'border-white'} flex items-center justify-center`}
                        >
                            <span className="tracking-wider">Hard</span>
                            <input
                                type="radio"
                                checked={feedback === 'Hard'}
                                className="hidden"
                                value={'HARD'}
                                onChange={(e) => handleSelect(e)}
                            />
                        </label>
                        <label
                            className={`h-[40px] rounded-full border bg-black text-center ${isSubmitted && feedback !== 'TOO_HARD' ? 'btn-disabled' : ''} ${feedback === 'TOO_HARD' ? 'pointer-events-none cursor-default border-secondary text-secondary shadow-glow' : 'border-white'} flex items-center justify-center`}
                        >
                            <span className="tracking-wider">Too Hard</span>
                            <input
                                type="radio"
                                checked={feedback === 'Too Hard'}
                                className="hidden"
                                value={'TOO_HARD'}
                                onChange={(e) => handleSelect(e)}
                            />
                        </label>
                    </form>
                    <Link
                        to={`/workout/${workout.id}`}
                        className="text-center text-sm font-bold text-gray-600 underline"
                    >
                        Skip
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FinishedWorkout;
